<template>
  <div class="permisos">
    <Navbar v-if="keyNavbar != 0" :key="keyNavbar"></Navbar>
    <div class="permisos-right">
      <div class="permisos-top">
        <p class="permisos-title">Permisos BASF</p>
        <label class="permisos-usuarios-length"
          >{{ users.length }} usuarios</label
        >
      </div>
      <div class="permisos-buscar-container">
        <div class="permisos-buscar">
          <input
            type="text"
            placeholder="Buscar"
            ref="buscarInput"
            @keyup="search"
          />
          <img src="../assets/img/buscar.png" />
        </div>
      </div>
      <div class="permisos-usuarios-container">
        <div class="permisos-usuario-row permisos-usuario-row-titulo">
          <label>Nombre de usuario</label>
          <label>C&O</label>
          <label>AC</label>
          <label>CC</label>
          <label>POG</label>
          <label>Pedidos</label>
          <label>Planes</label>
        </div>
        <div
          v-for="user in users"
          :key="user.id"
          class="permisos-usuario-row permisos-usuario-row-data"
        >
          <div class="permisos-usuario-nombre-container">
            <label class="permisos-usuario-nombre">{{ user.nombre }}</label>
            <label class="permisos-usuario-email">{{ user.email }}</label>
          </div>
          <select
            name="select"
            v-model="user.rolCYO"
            @change="onChange(user)"
            :disabled="!$usuario.rolCYO || $usuario.rolCYO.rol !== 'Performance'"
          >
            <option v-for="rol in roles" :key="rol.rol" :value="rol.rol">
              {{ rol.detail }}
            </option>
          </select>
          <select
            name="select"
            v-model="user.rolClientes"
            @change="onChange(user)"
            :disabled="!$usuario.rolClientes || $usuario.rolClientes.rol !== 'Performance'"
          >
            <option v-for="rol in roles" :key="rol.rol" :value="rol.rol">
              {{ rol.detail }}
            </option>
          </select>
          <select
            name="select"
            v-model="user.rolCC"
            @change="onChange(user)"
            :disabled="!$usuario.rolCC || $usuario.rolCC.rol !== 'Performance'"
          >
            <option v-for="rol in roles" :key="rol.rol" :value="rol.rol">
              {{ rol.detail }}
            </option>
          </select>
          <select
            name="select"
            v-model="user.rolPOG"
            @change="onChange(user)"
            :disabled="!$usuario.rolPOG || $usuario.rolPOG.rol !== 'Performance'"
          >
            <option v-for="rol in roles" :key="rol.rol" :value="rol.rol">
              {{ rol.detail }}
            </option>
          </select>
          <select
            name="select"
            v-model="user.rolPedidos"
            @change="onChange(user)"
            :disabled="!$usuario.rolPedidos || $usuario.rolPedidos.rol !== 'Performance'"
          >
            <option v-for="rol in roles" :key="rol.rol" :value="rol.rol">
              {{ rol.detail }}
            </option>
          </select>
          <select
            name="select"
            v-model="user.rolPlanComercial"
            @change="onChange(user)"
            :disabled="!$usuario.rolPlanComercial || $usuario.rolPlanComercial.rol !== 'Performance'"
          >
            <option v-for="rol in roles" :key="rol.rol" :value="rol.rol">
              {{ rol.detail }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <Loading v-show="showLoading"></Loading>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Loading from "@/components/Loading.vue";

export default {
  name: "permisos",
  components: { Navbar, Loading },
  data() {
    return {
      keyNavbar: 0,
      users: [],
      usersOG: [],
      roles: [
        {
          rol: "null",
          detail: "Sin Acceso",
        },
        {
          rol: "AccesoMercado",
          detail: "Acceso Mercado",
        },
        {
          rol: "Agro",
          detail: "Agro",
        },
        {
          rol: "Canje",
          detail: "Canje",
        },
        {
          rol: "Cobranzas",
          detail: "Cobranzas",
        },
        {
          rol: "Creditos",
          detail: "Créditos",
        },
        {
          rol: "CropManager",
          detail: "Crop Manager",
        },
        {
          rol: "CustomerCare",
          detail: "Customer Care",
        },
        {
          rol: "Distribuidor",
          detail: "Distribuidor",
        },
        {
          rol: "GerenteMarketing",
          detail: "Gerente de Marketing",
        },
        {
          rol: "GerenteVentas",
          detail: "Gerente de Ventas",
        },
        {
          rol: "Impuestos",
          detail: "Impuestos",
        },
        {
          rol: "Marketing",
          detail: "Marketing",
        },
        {
          rol: "Performance",
          detail: "Performance",
        },
        {
          rol: "Ventas",
          detail: "Ventas",
        },
      ],
      showLoading: false,
    };
  },
  created() {},
  mounted() {
    const that = this;
    this.$nextTick(() => {
      that.keyNavbar = Math.round(Math.random() * 1000);
    });
    this.getUsers();
  },
  methods: {
    getUsers() {
      this.showLoading = true;
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/usuarios")
        .then(function (response) {
          response.data.forEach((u) => {
            u.rolCYO = u.rolCYO != null ? u.rolCYO.rol : "null";
            u.rolClientes = u.rolClientes != null ? u.rolClientes.rol : "null";
            u.rolCC = u.rolCC != null ? u.rolCC.rol : "null";
            u.rolPOG = u.rolPOG != null ? u.rolPOG.rol : "null";
            u.rolPedidos = u.rolPedidos != null ? u.rolPedidos.rol : "null";
            u.rolPlanComercial = u.rolPlanComercial != null ? u.rolPlanComercial.rol : "null";
          });
          that.users = response.data;
          that.usersOG = response.data;
          that.showLoading = false;
        })
        .catch(function (error) {
          that.showLoading = false;
          // handle error
          console.error(error);
        });
    },
    update(payload, id) {
      console.log(payload);
      console.log(id);
    },
    onChange(u) {
      let user = JSON.parse(JSON.stringify(u));
      if (user.rolCYO != "null" && user.rolCYO != "SinAcceso") {
        user.rolCYO = {
          rol: user.rolCYO,
        };
      } else {
        user.rolCYO = null;
      }
      if (user.rolClientes != "null" && user.rolClientes != "SinAcceso") {
        user.rolClientes = {
          rol: user.rolClientes,
        };
      } else {
        user.rolClientes = null;
      }
      if (user.rolCC != "null" && user.rolCC != "SinAcceso") {
        user.rolCC = {
          rol: user.rolCC,
        };
      } else {
        user.rolCC = null;
      }
      if (user.rolPOG != "null" && user.rolPOG != "SinAcceso") {
        user.rolPOG = {
          rol: user.rolPOG,
        };
      } else {
        user.rolPOG = null;
      }
      if (user.rolPedidos != "null" && user.rolPedidos != "SinAcceso") {
        user.rolPedidos = {
          rol: user.rolPedidos,
        };
      } else {
        user.rolPedidos = null;
      }
      if (user.rolPlanComercial != "null" && user.rolPlanComercial != "SinAcceso") {
        user.rolPlanComercial = {
          rol: user.rolPlanComercial,
        };
      } else {
        user.rolPlanComercial = null;
      }
      console.log(user);
      this.$axios
        .put(this.$localurl + "/api/usuario/permisos", user)
        .then(function () {})
        .catch(function(error) {
          console.error(error);
        });
    },
    search() {
      let filterSearch = this.$refs.buscarInput.value.toUpperCase();

      this.users = this.usersOG.filter(
        (d) =>
          (d.nombre != null ? d.nombre : "")
            .toUpperCase()
            .indexOf(filterSearch) > -1 ||
          (d.email != null ? d.email : "").toUpperCase().indexOf(filterSearch) >
            -1
      );
    },
  },
};
</script>

<style scoped src="../assets/css/views/permisos.css"></style>